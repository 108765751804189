import React, { useState } from 'react';
import { Carousel, CarouselItem } from 'react-bootstrap'; // Remove CarouselIndicators import
// import ExampleCarouselImage from './ExampleCarouselImage';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import image1 from '../img/about.jpg';
// import image2 from '../img/hero-bg.jpg';
import image3 from '../img/1.JPG'
import image4 from '../img/2.JPG'
import image1 from '../img/3.JPG'
import image2 from '../img/5.JPG'
// import image3 from '../img/workwithus.jpg';

const items = [
  {
    id: 1,
    imageUrl: image1,
    text: 'Slide 1',
  },
  {
    id: 2,
    imageUrl: image2,
    text: 'Slide 2',
  },
  {
    id: 3,
    imageUrl: image3,
    text: 'Slide 3',
  },
  {
    id: 4,
    imageUrl: image4,
    text: 'Slide 3',
  },
];

const CarouselImages = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={3000}>
      {items.map((item) => (
        <CarouselItem key={item.id}>
          {/* <ExampleCarouselImage imageUrl={item.imageUrl} text={item.text} /> */}
          <div className="d-flex align-items-center justify-content-center">
      <img
        className="img-fluid"
        style={{ maxWidth: '80%', maxHeight:'80%'}}
        src={item.imageUrl}
        alt={`Example slide with text: ${item.text}`}
      />
    </div>
        </CarouselItem>
      ))}
    </Carousel>
  );
};

export default CarouselImages;
