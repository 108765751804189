import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function ErrorModal({error, setError, errorMessage}) {
  return (
    <>
    <Modal
        show={error}
        onHide={()=>setError(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage}

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>setError(false)} >OK</Button>
        </Modal.Footer>
      </Modal>
      </>
  )
}
